import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={['pvc collection']}
        title="About"
      />
    </Layout>
  );
}

export default AboutPage;
